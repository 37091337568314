.carousel-inner{
    background-image: linear-gradient(45deg,
    rgba(135, 174, 220, 0.75),
    rgba(0, 0, 0, 0.80))
}
.carousel-text
h3 {
    font-size:1.9rem;
    font-weight:700;
    color:gray;
    z-index: 1; /* Ensure heading appears above carousel */
  }

  @media screen and(max-width:430px){
    .icon-section p{
        padding-top: 0%;
    }
    .carousel-text h3{
        font-size:1rem;
    }
    .icon-section .card-view img {
        width: 46%;
        padding: 5%;
    }
    .icon-section p {
        font-size: 15px;
    }
  }

  
  @media screen and(max-width:375px){
    .carousel-text h3 {
        font-size: 1rem;
    }
  }
  @media screen and(max-width:370px){
    .carousel-text h3 {
        font-size: 1.5rem;
    }
  }


  