
.card .card-body h5{
font-size:15px;
padding:2%;
color:#080028;
}
.card-body a{
    font-size:0.8rem;
}
@media only screen and (max-width: 740px) {
    .card{
        width:100%;
        margin:2%;
    }
    .card img{
        width:35%;
        margin-top:5%;
        margin-left:32%;
    }
    .card p{
       font-size:10px;
    }
}
@media only screen and (max-width: 310px) {
    .card{
        width:100%;
        margin:8%;
    }
    .card img{
        width:40%;
        margin-top:5%;
        margin-left:32%;
    }
    .card p{
       font-size:8px;
    }
    .card .card-body h5{
        font-size:8px;
    }
    
}