.fb-page{
    background-image: linear-gradient(45deg,
    rgba(135, 174, 220, 0.0),
    rgba(0, 0, 0, 0.60));
    background-color:#080028;
    background-size: cover;
}
/* .fb-page .fb-img img{
    height:78%; 
    padding-left:5%;
    padding-top:10%;

} */
@media only screen and (max-width: 740px){
    .fb-page{
      display:none;
    }
    
  }