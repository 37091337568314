
 .port-section {
    background-color: #EEEEEE; 
    }

.port-heading h2{
    font-weight:400;
    padding-top:5%;
    font-size: 76px;
    text-align: center;
    text-transform:uppercase;
}
.port-top h4{
    margin-top:5%;
    font-size:18px;
    font-weight:700;
    text-align: center;
}
.port-top {
  padding:0% 15%;
}
.port-top img {
    width:100%;
  }
  @media only screen and (max-width: 768px) {
    .port-top h4{
      font-size:13px;
    }
    .port-heading h2 {
      font-weight: 300;
      padding-top: 5%;
      font-size: 49px;
      text-align: center;
      text-transform: uppercase;
  }
  .port-top h4 {
    font-size: 13px;
    margin-bottom: 14%;
}
  }
  
  @media only screen and (max-width: 378px) {
    .port-heading h2 {
      font-size:49px;
    }

  }
  @media only screen and (max-width: 420px) {
    .port-heading h2 {
      font-size:49px;
    }

  }
  @media only screen and (max-width: 450px) {
      .port-heading h2 {
        margin-top: 20%;
        font-size: 49px;
    }
    .port-top h4 {
      font-size: 13px;
      margin-bottom: 14%;
  }
  .bg-form {
    height: 66vh;
}
.bg-form h3 {
  font-size: 16px;
}
    }