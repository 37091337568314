.main-con {
  background-image: linear-gradient(
      45deg,
      rgba(135, 174, 220, 0),
      rgba(0, 0, 0, 0.6)
    ),
    url("../../assets/banner/port_banner.jpg");
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center center;
  background-attachment: fixed;
}
.main-con .text-bot {
  margin-top: 10%;
  margin-left: -18%;
}
.main-con .text-bot span {
  font-size: 6.6rem;
  line-height: 1;
}
.main-con .text-bot p {
  font-size: 28px;
}
.sub-main {
  padding: 1%;
}
.sub-main h2 {
  color: #f77700;
}
/* .sub-main p{
    font-size:20px;
  }
  */
.sub-row {
  background: #eeeeee;
  padding: 2%;
}
.sub-row .sub-text p {
  align-items: justify;
}
.sub-maind {
  background: #eeeeee;
}

.sub-rowtwo {
  background: #eeeeee;
  padding: 3%;
}
.text-subdatatwo {
  background-image: url("../../assets/Porifilio/02.jpg");
  background-size: cover;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pro-subthree {
  background: #eeeeee;
  padding: 3%;
}

.pro-subfour {
  background-image: url("../../assets/Porifilio/03.jpg");
  background-size: cover;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pro-four {
  background: #eeeeee;
  padding: 3%;
}
.pro-subfour p {
  align-items: justify;
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .main-con {
    height: 70vh;
  }
  .main-con .text-bot {
    margin-left: 6%;
  }
  .main-con .text-bot span {
    font-size: 7rem;
    line-height: 1;
  }
  .main-con .text-bot p {
    font-size: 27px;
  }
  .text-subdatatwo {
    height:26vh;
  }
  .pro-subfour{
    height:26vh;
  }
}
@media only screen and (max-width: 768px) {
  .text-subdatatwo {
    height: 30vh;
  }
  .pro-subfour {
    height: 24vh;
  }
}

@media only screen and(max-width:607px) {
  .main-con .text-bot {
    margin-left: 6%;
  }
  .main-con .text-bot span {
    font-size: 7rem;
    line-height: 1;
  }
  .main-con .text-bot p {
    font-size: 25px;
  }
}
@media only screen and (max-width: 550px) {
  .main-con .text-bot {
    margin-top: 10%;
    margin-left: 11%;
  }
  .main-con .text-bot span {
    font-size: 4.6rem;
  }
  .main-con .text-bot p {
    font-size: 35px;
  }
}
@media only screen and (max-width: 440px) {
  .main-con {
    height: 40vh;
  }
  .main-con .text-bot span {
    font-size: 4.6rem;
    line-height: 1;
  }
  .main-con .text-bot {
    margin-top: 10%;
    margin-left: 5%;
  }
  .main-con .text-bot p {
    font-size: 30px;
  }
  .text-subdatatwo {
    height: 17vh;
  }
  .pro-subfour{
    height:17vh;
  }
}

@media only screen and (max-width: 430px) {
  .main-con {
    height: 40vh;
  }
  .main-con .text-bot {
    margin-left: 8%;
  }
  .main-con .text-bot span {
    font-size: 4.6rem;
    line-height: 1;
  }
  .main-con .text-bot span {
    font-size: 3.6rem;
    padding: 0%;
  }
  .main-con .text-bot p {
    font-size: 27px;
  }
  .pro-subfour {
    height: 13vh;
  }
  .text-subdatatwo {
    height: 17vh;
  }
  .main-con p {
    font-size: 29px;
    width: 100%;
    padding: 0%;
  }
}
@media only screen and (max-width: 402px) {
  .main-con {
    height: 40vh;
  }
  .main-con .text-bot {
    margin-left: 10%;
  }
  .main-con .text-bot span {
    font-size: 3rem;
    padding: 0%;
  }
  .main-con .text-bot p {
    font-size: 23px;
  }
  .main-con p {
    font-size: 23px;
    width: 100%;
    padding: 0%;
  }
}

@media only screen and (max-width: 375px) {
  .main-con .text-light h1 {
    font-size: 58px;
  }
  .main-con {
    height: 40vh;
  }
  .main-con .text-bot span {
    font-size: 3rem;
    /* padding: 36% 1%; */
  }
  .main-con .text-bot p {
    font-size: 19px;
    margin-left: -7%;
  }
  .main-con p {
    font-size: 23px;
    width: 100%;
    padding: 0% 8%;
  }
}
