@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

a{
  text-decoration:none;
}

.big-text {
    font-size:58px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    margin-top:15%;
    color:#5F5F5F;
    text-transform: uppercase;
    line-height:1.3;
  }
  .vertical-align {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }
.home-text {
  font-size:25px;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}
.home-text p{
  color:#080028;
}
.home-text .content button {
  width: 40%; /* Adjust the width as needed */
  margin-left:auto;
  background:#f77700;
  color:#fff;
  font-weight:700;
}
.home-text .content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-view{
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}
 .icon-section{
  padding-top:40px;
 }
 .icon-section .card-view img{
  width:60%;
  padding:10%;
  }
  .icon-section p{
    font-size:14px;
  }
 .icon-section .card-view h6{
  font-weight:700;
  padding-bottom:10%;
 }
 
 .banner {
  background-image: url('../../assets/icon/whoarewe.jpg');
  background-size: cover;
  height: 50vh; /* adjust height according to your requirement */
}
 .banner img {
  max-width: 100px;
  max-height: 100px;
 }
 ul li {
  list-style:none;
 }
 .banner-texthalf{
  padding:2% 8%;
 }
 .banner-texthalf h2{
  font-weight:700;
  color:#080028;
 }
 .banner-texthalf p{
  font-size:20px;
 }
 
 .banner-home{
  background-image: url('../../assets/banner/whoarewe.png');
  background-size: cover;
  height: 58vh;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center; 
 }
 
 .nav-item ul li > a:hover {color: rgba(255,0,0,1);}

 @media only screen and (max-width: 1390px) {
  .big-text {
     font-size: 50px;
   }
   .icon-section img{
     width:30%;
   }
   .home-text {
     font-size: 1.4rem;
   }
   .banner-home{
       height:50vh;
   }
  }

 @media only screen and (max-width: 1296px) {
  .big-text {
     font-size: 41px;
   }
   .icon-section img{
     width:30%;
   }
   .home-text {
     font-size: 1.4rem;
   }
   .bg-form{
     height: 60vh;
     }
     
 }

  @media only screen and (max-width: 1280px) {
    .big-text {
       font-size: 50px;
     }
     .icon-section img{
       width:30%;
     }
     .home-text {
       font-size: 1.4rem;
     }
     .banner-home{
         height:50vh;
     }
    }
 
 
 
 @media only screen and (max-width: 1194px) {
  .big-text {
     font-size: 45px;
   }
   .icon-section img{
     width:30%;
   }
   .home-text {
     font-size: 1.4rem;
   }
   .banner-home{
       height:36vh;
   }
  }

 @media only screen and (max-width: 1175px) {
  .big-text {
     font-size: 50px;
   }
   .icon-section img{
     width:30%;
   }
   .home-text {
     font-size: 1.4rem;
   }
   .bg-form{
     height: 60vh;
     }
 }
 @media only screen and (max-width: 1160px) {
  .big-text {
    font-size: 38px;
    line-height:1.4;
  }
  .icon-section img{
    width:30%;
  }
  .home-text {
    font-size: 1rem;
  }
  .bg-form{
    height: 60vh;
    }
    .banner-home {
     height: 35vh;
 }
 .icon-section .card-view img {
  width: 37%;
  padding: 6%;
}
}

 @media only screen and (max-width: 1080px) {
  .big-text {
    font-size: 38px;
    line-height:1.4;
  }
  .icon-section img{
    width:30%;
  }
  .home-text {
    font-size: 1rem;
  }
  .bg-form{
    height: 60vh;
    }
    .banner-home {
     height: 35vh;
 }
}

 

 @media only screen and (max-width: 1025px) {
  .big-text {
    font-size: 39px;
  }
  .icon-section img{
    width:30%;
  }
  .icon-section p {
    font-size: 21px;
}

  .icon-section .card-view h6 {
    font-weight: 700;
    padding-bottom: 10%;
    font-size: 26px;
  }
  .icon-section .card-view img {
    width: 39%;
    padding: 2%;
}
  
  .home-text {
    font-size: 1.4rem;
  }

  .banner-home {
    height: 20vh;
}
  .bg-form{
    height:37vh;

  }
  
 }
 
/* For screens between 768px and 1024px (typically tablets) */
@media only screen and(max-width: 1024px) {
  .big-text {
    font-size: 30px; /* Adjust as needed */
  }

  .icon-section img {
    width: 40%; /* Adjust as needed */
  }

  .home-text {
    font-size: 1rem; /* Adjust as needed */
  }
  .icon-section .card-view img {
    width: 23%;
    padding: 3%;
}
  .icon-section .card-view h6{
    font-size:1rem;
  }
  .icon-section p {
    font-size: 15px;
}
  .banner-home {
    height: 33vh; /* Adjust as needed */
  }
}


 
 @media only screen and (max-width: 990px){
  .big-text {
    font-size: 3.1rem;
  }
  .home-text {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 980px) {
  .big-text {
     font-size: 30px;
     line-height:1.4;
   }
   .icon-section img{
     width:30%;
   }
   .home-text {
     font-size: 1rem;
   }
   .bg-form{
     height: 60vh;
     }
     .banner-home {
      height: 30vh;
  }
 }

@media only screen and (max-width: 920px) {
  .big-text {
     font-size: 30px;
     line-height:1.4;
   }
   .icon-section img{
     width:30%;
   }
   .home-text {
     font-size: 1rem;
   }
   .bg-form{
     height: 60vh;
     }
     .banner-home {
      height: 18vh;
  }
 }

@media only screen and (max-width: 912px) {
  .big-text {
     font-size: 30px;
     line-height:1.4;
   }
   .icon-section img{
     width:30%;
   }
   .home-text {
     font-size: 1rem;
   }
   .bg-form{
     height: 60vh;
     }
     
 }

 @media only screen and (max-width: 882px) {
  .big-text{
    font-size:29px;
  }
  .home-text {
    font-size:15px;
  }
  .home-text .content button{
    font-size:15px;
  }
  .banner-home{
    height:20vh;
   }
   .home-text p{
    font-size:1rem;
   }
   .bg-form{
    height:60vh;
   }
 }
 
 @media only screen and (max-width: 800px){
  .big-text {
    font-size: 1.9rem;
  }
  .home-text p {
    font-size: 1rem;
  }

}


 @media only screen and (max-width: 780px) {
  .big-text  {
    font-size: 1.8rem;
  }
  .home-text {
    font-size: 1rem;
  }
}
 
/* For screens smaller than 768px (typically phones) */
@media only screen and (max-width: 768px) {
  .big-text {
    font-size: 28px; /* Adjust as needed */
  }

  .icon-section img {
    width: 50%; /* Adjust as needed */
  }
  .icon-section .card-view img {
    width: 36%;
    padding: 5%;
}

  .home-text {
    font-size: 1.2rem; /* Adjust as needed */
  }
  .banner-home {
    height: 22vh;
} /* Adjust as needed */
.icon-section .card-view h6{
  font-size:20px;
}
.icon-section p {
  font-size: 12px;
}
.sub-row .sub-text p {
  font-size: 14Px;
}
.pro-subthree h2{
  font-size:22px;
}

}


@media only screen and (max-width: 740px){
  .icon-section{
    display:none;
  }
}



 @media only screen and (max-width: 440px) {
  .banner-home{
    height:14vh;
    padding:5%;
   }
   .icon-section .card-view h6{
    font-size:1rem;

   }
   .icon-section .card-view img {
    width: 46%;
    padding: 3%;
}
 }
 
 @media only screen and (max-width: 420px){
  .banner-home {
      height: 14vh;
  }
}

 @media only screen and (max-width: 375px) {
  .banner-home{
    height:15vh;
    padding:5%;
   }
   .carousel-text h3{
    font-size:0.9rem;
   }
   .bg-form{
    height:85vh;
   }
 }
 
 @media only screen and(max-width:350px){
  .carousel-text h3 {
    font-size: 22px;
  }
  .banner-home {
    height: 11vh;
    padding: 5%;
}
 }
 
 


 

 



 
 



