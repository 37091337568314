 .cmt-section {
    background-color: #EEEEEE; 
    margin-top:8%;
}
.cmt-top{
    padding-left:13rem;
}
.cmt-heading h2 {
    padding-top:5%;
    font-size: 76px;
    font-weight:400;
    text-align: center;
    text-transform:uppercase;
}
.cmt-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding:2%;

}
.cmmtimg img{
    height:50vh;
    width:62%;
    overflow: hidden;
    }


@media only screen and (max-width: 1280px) {
    .cmt-heading h2 {
        font-size:67px;
    }
    .cmt-content p {
        margin-left: -18%;
        font-size: 11px;
    }
    .cmt-content h4{
        font-size:15px;
    }
    .cmt-content .btn{
        margin-left:-90px; 
    }
    
    .cmmtimg img{
        margin-left: 14%;
        margin-top: 8%;
        height: 33vh;
        width: 54%;
    }
    .bg-form{
        height:60vh;
    }
}
@media only screen and (max-width: 1025px) {
    .cmt-heading h2 {
        font-size:54px;
    }
    .cmt-content p {
        margin-left: -25%;
        font-size: 14px;
    }
    .cmt-content h4{
        font-size:15px;
    }
    .cmt-content .btn{
        margin-left:-100px; 
    }
    
    .cmmtimg img{
        margin-left: 6%;
        margin-top: 2%;
        height: 17vh;
        width: 62%;
    }
    .bg-form{
        height:60vh;
    }
    
}

@media only screen and (max-width: 1000px){
    .cmt-heading h2 {
        font-size:54px;
    }
    .cmt-content p {
        margin-left: -37%;
        font-size: 14px;
    }
    .cmt-content h4{
        font-size:15px;
    }
    .cmt-content .btn{
        margin-left:-126px; 
    }
    
    .cmmtimg img{
        margin-left: 14%;
        margin-top: 2%;
        height: 29vh;
        width: 62%;
    }
    .bg-form{
        height:60vh;
    }
}


@media only screen and (max-width: 940px){
    .cmt-heading h2 {
        font-size:54px;
    }
    .cmt-content p {
        margin-left: -41%;
        font-size: 14px;
    }
    .cmt-content h4{
        font-size:15px;
    }
    .cmt-content .btn{
        margin-left:-130px; 
    }
    
    .cmmtimg img{
        margin-left: 14%;
        margin-top: 2%;
        height: 20vh;
        width: 62%;
    }
    .bg-form{
        height:60vh;
    }
    /* .banner-home {
        height: 19vh;
    } */
}

@media only screen and (max-width: 870px){
    .cmt-heading h2{
        font-size:42px;
    }
    .cmt-content p {
        margin-left: -33%;
        font-size: 14px;
    }
    .cmt-content .btn {
        margin-left: -100px;
    }
    .cmmtimg img {
        margin-left: -5%;
        margin-top: 2%;
        height: 20vh;
        width: 72%;
    
    }
    .cmt-top{
    width:100%
    }
 }

 @media only screen and (max-width: 830px) {
    .cmt-heading h2 {
         font-size: 43px;
         font-weight: 400;
     }
     .cmt-content p {
         margin-left: -44%;
         font-size: 11px;
     }
     .cmt-content h4{
         font-size:15px;
     }
     .cmt-content .btn{
         margin-left:-120px; 
     }
     
     .cmmtimg img{
         margin-left: -8%;
         margin-top: 4%;
         height: 23vh;
         width: 86%;
     }
     
        .bg-form {
            height: 27vh;
     }
     .bg-form h3{
         font-size:18px;
     }
     .form-control{
         font-size:13px;
     }
    
 }
 @media only screen and (max-width: 768px) {
   .cmt-heading h2 {
        font-size: 43px;
        font-weight: 400;
    }
    .cmt-content p {
        margin-left: -52%;
        font-size: 11px;
    }
    .cmt-content h4{
        font-size:15px;
    }
    .cmt-content .btn{
        margin-left:-133px; 
    }
    
    .cmmtimg img{
        margin-left: -8%;
        margin-top: 4%;
        height: 23vh;
        width: 86%;
    }
    .bg-form {
        height: 50vh;
    }
    .bg-form h3{
        font-size:18px;
    }
    .form-control {
        font-size: 13px;
    }
   
}

@media only screen and (max-width: 550px) {
    .bg-form{
      height:70vh;
     }
     .cmt-heading h2 {
        font-size: 29px;
        font-weight: 400;
    }
    .cmt-content p {
        margin-left: -66%;
        font-size: 11px;
    }
    .cmt-content .btn {
        margin-left: -174px;
    }
    .cmmtimg img {
        margin-left: -32%;
        margin-top: 4%;
        height: 41vh;
        width: 105%;
    }
    .form-control {
        font-size: 13px;
        width: 127%;
        margin-left: -12%;
    }
  }
 @media only screen and (max-width: 450px) {
    .cmt-heading h2 {
        font-size:22px;
        font-weight:700;
    }
    .cmt-content p {
        margin-left: -84%;
        font-size: 11px;
    }
    .cmt-content h4{
        font-size:15px;
    }
    .cmt-content .btn{
        margin-left:-165px; 
    }
    .cmmtimg img{
        margin-left: -84%;
        margin-top: 19%;
        height: 31vh;
        width: 159%;
    }
}

 @media only screen and (max-width: 420px) {
    .cmt-heading h2 {
        font-size:22px;
        font-weight:700;
    }
    .cmt-content p {
        margin-left: -96%;
        font-size: 11px;
    }
    .cmt-content h4{
        font-size:15px;
    }
    .cmt-content .btn{
        margin-left:-172px;
    }
    .cmmtimg img{
        margin-left: -100%;
        margin-top: 23%;
        height: 31vh;
        width: 178%;
    }
}

 @media only screen and (max-width: 378px) {
    .cmt-heading h2 {
        font-size:22px;
        font-weight:700;
    }
    .cmt-content p {
        margin-left: -136%;
        font-size: 11px;
    }
    .cmt-content h4{
        font-size:15px;
    }
    .cmt-content .btn{
        margin-left:-188px;
    }
    .cmmtimg img{
        margin-left: -147%;
        margin-top: 23%;
        height: 34vh;
        width: 236%;
    }
}

@media only screen and (max-width: 344px) {
    .cmt-heading h2 {
        font-size:19px;
        font-weight:500;
    }
    .cmt-content p {
        margin-left: -162%;
        font-size: 11px;
    }
    .cmt-content h4{
        font-size:12px;
    }
    .cmt-content .btn{
        margin-left:-177px;
    }
    .cmmtimg img{
        margin-left: -195%;
        margin-top: 23%;
        height: 28vh;
        width: 266%;
    }
    .bg-form h3{
        font-size:15px;
    }
    .bg-form {
        height: 51vh;
    }
    .banner-home {
        height: 11vh;
        padding: 5%;
    }
}
@media only screen and (max-width: 320px) {
    .cmt-heading h2 {
        font-size:19px;
        font-weight:500;
    }
    .cmt-content p {
        margin-left: -397%;
        font-size: 11px;
    }
    .cmt-content h4{
        font-size:12px;
    }
    .cmt-content .btn{
        margin-left:-188px;
    }
    .cmmtimg img{
        margin-left: -569%;
        margin-top: 23%;
        height: 36vh;
        width: 551%;
    
    }
    .bg-form h3{
        font-size:15px;
    }
    .bg-form {
        height: 71vh;
    }
    .banner-home {
        height: 13vh;
        padding: 0%;
    }
    .carousel-text h3{
        font-size:1rem;
    }
}